import ru from "./ru"
import en from "./en"
import uk from "./uk"
import it from "./it"
import es from "./es"
import de from "./de"
import fr from "./fr"
import pl from "./pl"

export default {
    pl,
    fr,
    de,
    ru,
    en,
    uk,
    it,
    es
}
